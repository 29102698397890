import { Link } from 'react-router-dom'
import './banner.css'
export default function Banner() {
  return (
    <div className="banner1">
      <div className="banner1__container">
        <div className="banner1__inner">

          <div className="banner1__card">
            <div className="banner1__card__int">75$</div>
            <div className="banner1__card__text">В день! Средний заработок трейдера</div>
          </div>

          <div className="banner1__card">
            <div className="banner1__card__int">1000+</div>
            <div className="banner1__card__text">Положительных отзывов</div>
          </div>
          
          <div className="banner1__card">
            <div className="banner1__card__int">300+</div>
            <div className="banner1__card__text">
              Сигналов в день от лучших трейдеров
            </div>
          </div>

          <div className="banner1__bottom">
            <div className='banner1__bottom__text'>полная инструкция по получению сигналов</div>
            
            <Link to="/signals" className="banner1__bottom__btn"><span className="banner1__bottom__btn-inner">смотреть</span></Link>
          </div>

        </div>
        
      </div>
    </div>
  )
}