import logoColor from '../logo-color.svg';
import { useEffect, useState } from "react";
import { APP_URI } from "../constants";
import Image from 'rc-image'
import './signals.css'
import '../components/gallery.css'
import Accordion from '../components/Accordion/Accordion';
import { X, ChevronLeft, ChevronRight, RotateCcw, RotateCw, ZoomIn, ZoomOut } from 'react-feather';
import Recommendations from '../components/Recomendations';


const Forex = () => {
  return <div>
    <section className="section bg-white">
      <div className="title2 tac">В разработке</div>
    </section>
  </div>
}


const Cryptocurrency = () => {
  return <div>
    <section className="section bg-white">
      <div className="title2 tac">В разработке</div>
    </section>
  </div>
}


const Options = () => {
  return (
    <div>
      
      <div className="signals-guide">
          <div className="signals-section">
            <div className="container">
              <div className="row">

                <div className="px10 col12--lg">
                  <div className="line signal-text-line">
                    <h2 className="text3">
                      Бесплатные сигналы для Бинарных Опционов
                    </h2>
                    <p className="text4">
                      Если вы еще не попали в наш приватный Telegram-чат, то тогда у вас есть отличная возможность это сделать прямо сейчас. Переходите в бота и смотрите подробную инструкцию. ( До встречи )
                    </p>
                  </div>
                  <div className="row">
                    <div className="px10 col12--lg">
                      <div className="arrow">
                        <svg
                          width="497"
                          height="71"
                          viewBox="0 0 497 71"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M415.018 37.789L415.735 40.1841L415.018 37.789ZM495.687 11.7996C496.902 12.455 497.356 13.9714 496.7 15.1867L486.02 34.9904C485.365 36.2057 483.849 36.6595 482.633 36.0042C481.418 35.3488 480.964 33.8323 481.62 32.6171L491.113 15.0138L473.51 5.52046C472.294 4.86508 471.84 3.34863 472.496 2.13338C473.151 0.918122 474.668 0.464244 475.883 1.11962L495.687 11.7996ZM414.301 35.394L493.783 11.605L495.217 16.395L415.735 40.1841L414.301 35.394ZM1.72166 34.9425C136.232 75.5225 279.702 75.6795 414.301 35.394L415.735 40.1841C280.197 80.7505 135.726 80.5924 0.277511 39.7294L1.72166 34.9425Z"
                            fill="#434343"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="px10 col12--lg">
                      <a className='btn-rounded' href="https://t.me/fxleader_bot" target='_blank'>ПЕРЕЙТИ В БОТА</a>
                    </div>
                  </div>

                </div>
                <div className="px10 col12--lg video-wrapper">

                    <div className="guide-video-container">
                      <div className="guide-video-bg">
                        <iframe className='guide-video' src="https://www.youtube.com/embed/Hxr9F0wGFGY" title="Инструкция. Как получить доступ к сигналам через бота " frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="text23">Пошаговая видео инструкция по получению наших сигналов!</div>
                </div>

              </div>

              </div>
          </div>


        </div>
        

      <section className="section">
        <div className="container">
          <div className="tac mb30">
            <h2 className="title2 fx-color">Каждому нашему партнеру ПОДАРОК</h2>
            <h2 className="title2 fw5">Доступ в группу сигналов всех трейдеров</h2>
          </div>

          <div className="row text tac mb50">
            <div className="col8--md px10"><div className="bg-white fc md--mb15 h100--md px32 py20">Каждый день более 300 сигналов</div></div>
            <div className="col8--md px10"><div className="bg-white fc md--mb15 h100--md px32 py20">Сигналы с утра до вечера</div></div>
            <div className="col8--md px10"><div className="bg-white fc md--mb15 h100--md px32 py20">Трейдинг АИ робот</div></div>
          </div>

          <div className="py20 mb50">
            <h4 className="title2 fx-color">Что ты выберешь?</h4>
            <p className="text">
              1. Сотню раз создавать аккаунты по ссылкам трейдеров и пополнять депозит на каждом из них пока не найдешь того,кто лучше всего даёт сигналы, слив перед этим пару тысяч рублей…
            </p>
            <p className="text">2. Или перейти на СИГНАЛЫ ВСЕХ ТРЕЙДЕРОВ и уже сейчас получить доступ к сигналам всех популярных трейдеров</p>
            <p className="text">Написали максимально подробно и просто, думаем любой чайник разберется. До встречи в приватке!</p>
          </div>

          <Accordion title='С чего начинаем работу?' key={11}>
            <ol className='text'>
                <li className="list-item">
                  Копирование сигналов в чате Telegram ежедневно
                </li>
                <li className="list-item">
                  Перед началом у вас должен быть открыт брокер на ПК, планшете,
                  либо смартфоне (Последние 2 варианта через моб.сайт брокера)
                </li>
                <li className="list-item">
                  Чётко следовать рекомендациям и не медлить
                </li>
                <li className="list-item">
                  Срок экспирации всех сигналов М1-30 (1-30 минут)
                </li>
                <li className="list-item">Включаем уведомление в телеграм</li>
              </ol>
          </Accordion>

          <Accordion title='Как попасть в приватный чат Telegram бесплатно и быстро?'>
            <div className="privatechat-manual">
              <ol className='text'>
                <li className="manual-item">
                  Самый быстрый и простой способ сделать все по простой инструкции
                  нашего{" "}
                  <a href="https://t.me/fxleader_bot" target='_blank' className="tg-bot">
                    ТЕЛЕГРАМ БОТА
                  </a>
                </li>
                <li className="manual-item">
                  Так как все бесплатно, открываем доступ для наших партнеров после регистрации на брокере по
                  этой ссылке <a href="https://u2.shortink.io/register?utm_campaign=2535&utm_source=affiliate&utm_medium=sr&a=7JJV7zfhF31zjy&ac=ytshorts&code=PROMOCODE100" target='_blank' className="website-link">POCKET</a>
                </li>
                <li className="manual-item">
                  Если у вас уже был аккаунт, то его можно удалить, почистить историю и сделать новый.
                </li>
                <li className="manual-item">
                  Все партнеры получают КЕШ БЕКИ, рекомендации и т.д.
                </li>
                <li className="manual-item">
                  После регистрации нужно прислать свой ID аккаунта на проверку в <a href="https://t.me/fxleader_support" target='_blank' className="link-verify">SUPPORT</a> (мы онлайн 24/7)
                </li>
              </ol>
            </div>
          </Accordion>


          <Accordion title='Где посмотреть свой ID'>
            <div className="screenshots row">
              <div className="px10 px25--md col12--lg">
                <div className="relative">
                  <div className="number-screen">1</div>
                  <img className='shot-img' src={APP_URI+"/assets/screenshot1.png"} />
                </div>

              </div>
              <div className="px10 px25--md col12--lg">
                <div className="relative">
                  <div className="number-screen">2</div>
                  <img className='shot-img' src={APP_URI+"/assets/screenshot2.png"} />
                </div>
              </div>
            </div>
          </Accordion>


        </div>
      </section>
      <div className="container">
        <Recommendations/>
      </div>
      
    </div>
  )
}

const Reviews = () => {
  const slides = [
    "1.png",
    "2.png",
    "3.png",
    "4.jpg",
    "5.jpg",
    "6.jpg",
    "7.jpg",
    "8.jpg",
    "9.jpg",
    "10.jpg"
  ]
  const [currentSlide, setCurrentSlide] = useState(0)
  const [visible, setVisible] = useState(false);
  
  const prev = () => {
    if(currentSlide !== 0) {
      return setCurrentSlide(slide => slide - 1)
    }
    setCurrentSlide(slides.length-1)
  }
  const next = () => {
    if(currentSlide < slides.length-1) {
      return setCurrentSlide(slide => slide + 1)
    }
    setCurrentSlide(0)
  }
  useEffect(()=>{
    visible ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset')
    return () => {
      document.body.style.overflow = 'unset'
    }
  },[visible])
  return <div className="reviews-container">
    <div className="reviews__container">
      <div className="reviews">
        <div className="reviews-wrapper">
        <Image.PreviewGroup
          preview={{
            current: currentSlide,
            visible: visible,
            onVisibleChange: (value) => setVisible(value),
          }}
          icons={{
            close: <X/>,
            left: <ChevronLeft/>,
            right: <ChevronRight/>,
            rotateLeft: <RotateCcw/>,
            rotateRight: <RotateCw/>,
            zoomIn: <ZoomIn/>,
            zoomOut: <ZoomOut/>
          }}
        >
          {slides.map((slide) => <Image
              rootClassName={`review__image`}
              key={slide}
              src={`https://fxleader.net/reviews/review-${slide}`}
            />
            )}
        </Image.PreviewGroup>
        </div>
      </div>
      <div className="reviews__bottom">
        <div className="reviews__text">Больше отзывов на нашем официальном канале</div>
        <a href="https://t.me/trade_investor" target='_blank' className="reviews__btn">подписаться</a>
      </div>

    </div>
  </div>
}



export default function Signals() {
  const [currentTab, setCurrentTab] = useState(1)
  const [showReviews, setShowReviews] = useState(false)
  const toggleReviews = () => setShowReviews(p=>!p)

  return (
    <div className="main">
      <div id="signals">
        
        <div className="container">
          <div className="home">
            <div className="home__logo">
              <img className='fx-logo' src={logoColor} alt="FXLEADER" />
              <div className="text22">Нам доверяют свыше 3500 трейдеров</div>
            </div>
            <div className="home__content">
              <div className="line line-desktop">
                <h1 className="title2 mb30">
                  Получайте бесплатные сигналы от команды <span className="fx-color">fx&nbsp;leader</span>
                </h1>
                <p className="line line-mobile text2">
                  Наши трейдеры ежедневно проводят анализ рынка и предоставляют
                  возможность каждому желающему попробовать себя в мире трейдинга!
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col8--md px25--md">
                      
                <div className="card">
                  <div className="card-figure">1000+</div>
                  <div className="card-text">Положительных<br/>отзывов</div>
                  <button className="card-button" onClick={toggleReviews}>{showReviews ? 'скрыть' : 'смотреть'}</button>
                </div>
              </div>
              {showReviews ? <Reviews/> : null}

              <div className="col8--md px25--md">
                <div className="card">
                  <div className="card-figure">300+</div>
                  <div className="card-text">Сигналов в день от лучших трейдеров</div>
                </div>
              </div>

              <div className="col8--md px25--md">
                <div className="card">
                  <div className="card-figure">75$</div>
                  <div className="card-text">В день! Средний заработок трейдера</div>
                </div>
              </div>

            </div>
          </div>
        </section>


        <div className="container">
          <div className="mb30 tac">
            <h2 className="title2">инструкция по получению наших сигналов</h2>
          </div>

          <div className="df signals-tabs">
            <div className="md--cola col8--md px25--md">

              <div onClick={()=>setCurrentTab(1)} className={1===currentTab ? "tab active" : 'tab'}>
                <div className="tab__inner"><span className="inner-text">бинарные опционы</span></div>
              </div>
            </div>

            <div className="md--cola col8--md px25--md">
              <div onClick={()=>setCurrentTab(2)} className={2===currentTab ? "tab active" : 'tab'}>
                <div className="tab__inner"><span className="inner-text">криптовалюта</span></div>
              </div>
            </div>

            <div className="md--cola col8--md px25--md">
              <div onClick={()=>setCurrentTab(3)} className={3===currentTab ? "tab active" : 'tab'}>
                <div className="tab__inner"><span className="inner-text">форекс</span></div>
              </div>
            </div>

          </div>
        </div>


      </div>

      {currentTab === 1 ? <Options/> : null}
      {currentTab === 2 ? <Cryptocurrency/> : null}
      {currentTab === 3 ? <Forex/> : null}



{/* 
        <h2 className="text5">
          как попасть в приватный чат telegram бесплатно и быстро?
        </h2>



        <div className="view-id">Где посмотреть свой ID</div>


        <div className="screenshots row--lg">
          <div className="col12--lg">
            <div className="screen-map">
              <div className="number-screen">1</div>
              <img className='shot-img' src={APP_URI+"/assets/screenshot1.png"} />
            </div>

          </div>
          <div className="col12--lg">
            <div className="screen-map">
              <div className="number-screen">2</div>
              <img className='shot-img' src={APP_URI+"/assets/screenshot2.png"} />
            </div>
          </div>
          
        </div>


        <div className="list-gifts">
          <div className="text6">
            Дожидаетесь нашего одобрения и вы в нашей VIP группе
          </div>

          <div className="gift text7">Каждому нашему партнеру ПОДАРОК</div>
          <ul>
            <li className="gift">Доступ в группу сигналов всех трейдеров</li>
          </ul>
          <div className="gift">
            <span className="dash">—</span> каждый день более 500 сигналов
          </div>
          <div className="gift">
            <span className="dash">—</span> сигналы с утра до вечера
          </div>
          <div className="gift">
            <span className="dash">—</span> Трейдинг АИ робот
          </div>
        </div>

 */}

    </div>
  );
}
